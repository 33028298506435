<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"> -->
      <!--<el-breadcrumb separator="/">
                <el-breadcrumb-item>商户管理</el-breadcrumb-item>
                <el-breadcrumb-item>商户信息管理</el-breadcrumb-item>
            </el-breadcrumb>-->
    <!-- </div> -->
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon">
        </div>
        查看计费规则
      </h2>
      <!--搜索条件区域-->
      <div>
        <div class='top'>
          <el-form label-position="right"
                   label-width="140px"
                   :model="formInline"
                   style="width: 300px;margin-left:50px">
            <el-form-item label="规则名称："
                          prop="feeName">
              <span>{{formInline.feeName}}</span>
            </el-form-item>
            <el-form-item label="编码："
                          prop="feeCode">
              <span>{{formInline.feeCode}}</span>
            </el-form-item>
            <el-form-item label="描述："
                          prop="description"
                          style='width:550px;word-break: break-all;'>
              <!-- <div>{{formInline.description}}</div> -->
              <el-input type="textarea"
                        :readonly="true"
                        :rows="8"
                        v-model="formInline.description"></el-input>
            </el-form-item>
          </el-form>
          <el-form label-position="right"
                   label-width="140px"
                   :model="formInline"
                   style="width: 300px;margin-left:50px;">
            <el-form-item label="所属商户："
                          prop="operationManagerName">
              <span>{{formInline.operationName}}</span>
            </el-form-item>
            <el-form-item label="操作人："
                          prop="operationManagerName">
              <span>{{formInline.operationManagerName}}</span>
            </el-form-item>
            <el-form-item label="操作时间："
                          prop="updatedTime">
              <span>{{formInline.updatedTime}}</span>
            </el-form-item>
            <el-form-item label="免费分钟数：">
              <span>{{source.freeTime}}</span>
            </el-form-item>
            <el-form-item label="24小时最高收费：">
              <span>{{source.limit24hour}}元</span>
            </el-form-item>
          </el-form>
        </div>
        <!-- <div class='middle'>

          </div> -->
        <div class='bottom'>
          <el-form label-position="right"
                   label-width="140px"
                   class='b_form'
                   v-for='(rule) in source.rules'
                   :key='rule.cid'>
            <el-form-item label="生效日期：" prop="description">
              <span>{{rule.date}}</span>
            </el-form-item>
            <el-form-item label="工作日计费规则："></el-form-item>
            <div v-for='(detail, index) in rule.details'
                 :key='detail.cid'>
              <el-form-item :label="'时段' + (index +  1) +'：'">
                <span>{{detail.start + '-' + detail.end}}</span>
<!--            <span v-if='detail.items[0].type != 1' class='feeDis'>时段最高金额：{{detail.limit}}</span>-->
              </el-form-item>
              <el-form-item :label="'区间' + (index +  1) + '：'"
                            prop="description"
                            v-for='(item, index) in detail.items'
                            :key='item.cid'>
                <!-- <span>{{item.segment}}分钟</span> -->
                <span>计费方式： {{item.type == 2 ? '周期' : item.type == 0 ? '基础时段' : '次'}}</span>
<!--            <span class="feeDis">{{ item.segment }}分钟</span>-->
                <span class='feeDis'>{{item.unit}}元/{{item.type == 1 ? '次': item.period + '分钟'}}</span>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="text-align:center">
          <el-button @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  dateFormat
} from '@/common/js/public.js'
export default {
  name: 'hello',
  data() {
    return {
      feeId: '',
      formInline: {
        feeName: '',
        feeCode: '',
        updatedTime: '',
        createdTime: '1',
        assetModel: '',
        description: '',
        creatorId: ''
      },
      description: '',
      source: []
    }
  },
  methods: {
    showFormatter(data) {
      return dateFormat(data)
    },
    getRuleData() {
      this.$axios.get('/acb/2.0/fee/byId/' + this.feeId).then(res => {
        if (res.state == 0) {
          let { feeName, operationName, feeCode, updatedTime, createdTime, description, operationManagerName } = res.value;
          this.formInline = { feeName, operationName, feeCode, updatedTime, createdTime, description, operationManagerName };
          this.source = JSON.parse(res.value.property);
          this.source.rules.forEach(item => {
            if (!item.holiday.length) {

            }
          })
          this.isBind = res.value.isBind;
        } else {
          this.$alert(res.desc);
        }
      })
      //  let opt = {
      //    method: 'get',
      //    url: '/tenant/2.0/fee/byId/' + this.feeId,
      //    success: (res) => {
      //      if (res.state == 0) {
      //          let {feeName, feeCode, updatedTime, createdTime, description, operationManagerName} = res.value;
      //          this.formInline = {feeName, feeCode, updatedTime, createdTime, description, operationManagerName};
      //          this.source = res.value.source;
      //      } else {
      //        this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //           confirmButtonText: this.$t('pop_up.Determine')
      //         });
      //      }
      //    }
      //  }
      //  this.request(opt)
    }
  },
  components: {},
  mounted() {
    this.feeId = this.$route.query.feeId;
    this.getRuleData();
  },
  computed: {}
}
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
  .el-form-item
    margin 0
.top
  border 1px solid #e1e1e1
  border-radius 5px
  padding 10px 0
.middle
  border 1px solid #e1e1e1
  border-radius 5px
  padding 10px 0
  margin 10px 0
.bottom
  .b_form
    border 1px solid #e1e1e1
    padding 10px 50px
    border-radius 5px
    margin 20px 0
    h2
      line-height 40px
      color #333
      font-weight 400
.feeDis
  margin-left 20px
</style>
